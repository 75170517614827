import { Controller } from 'stimulus';

export default class LicenseController extends Controller {
  static targets = ['form', 'shouldObfuscateLicense'];

  connect() {}

  submitLicense(e) {
    e.preventDefault();

    this.shouldObfuscateLicenseTarget.value = 'true';
    this.formTarget.submit();
  }
}
